import React, { useState, useEffect } from 'react';
import './Section2.css';

const images = [
  { src: '/aaf.png', title: 'Building Legacies', body: 'With over six decades of excellence, we have been a cornerstone in shaping modern Libya, combining innovation with a deep respect for tradition.' },
  { src: '/aaq.png', title: 'A Vision for Tomorrow', body: 'Guided by a forward-thinking vision, we continue to redefine the engineering landscape, pioneering projects that stand the test of time.' },
  { src: '/abb.png', title: 'Architecting the Future', body: 'Our architectural expertise merges creativity with functionality, designing iconic structures that define Libya’s skyline and enrich its cultural heritage.' },
  { src: '/abm.png', title: 'Foundations of Progress', body: 'Through collaboration and innovation, we transform ideas into reality, engineering solutions that enhance lives and communities.' },
];

const Section2 = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  const handleIndicatorClick = (index) => {
    setCurrentIndex(index); // Set current index on indicator click
  };

  const currentImage = images[currentIndex];

  return (
    <div className="section2" id="section2">
      <div className="section2-content-container">
        <div className="section2-text-content">
          <h2>{currentImage.title}</h2>
          <p>{currentImage.body}</p>
          <button className="section2-learn-more">Learn More</button>
        </div>
        <div className="section2-gallery-content">
          <img src={currentImage.src} alt={currentImage.title} className="section2-gallery-image" />
        </div>
      </div>
      
      <div className="section2-progress-indicators">
        {images.map((_, index) => (
          <div
            key={index}
            className={`section2-indicator ${index === currentIndex ? 'active' : ''}`}
            onClick={() => handleIndicatorClick(index)}
          />
        ))}
      </div>
      
      {/* Horizontal Line Image */}
      <img src="/line.png" alt="Decorative Line" className="section2-line" />
    </div>
  );
};

export default Section2;
