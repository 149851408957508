import React, { useState, useEffect } from 'react';
import './Section3.css';

const newsHeadlines = [
  { image: '/newsImage1.png', title: 'Cyrene Declaration', subtitle: 'A sustainable region' },
  { image: '/newsImage2.png', title: 'Engineers Syndicate Conference', subtitle: 'Benghazi construction exhibition' },
  { image: '/newsImage3.png', title: 'A Glimpse into the Past', subtitle: 'Exploring our heritage, a selection of project photos' },
];

const galleryImages = [
  { src: '/newsnumber1.png', title: 'Cyrene Declaration', body: 'A sustainable region.' },
  { src: '/newsnumber3.png', title: 'Engineers Syndicate Conference', body: 'Benghazi construction exhibition.' },
  { src: '/newnumber2.png', title: 'A Glimpse into the Past', body: 'Exploring our heritage, a selection of project photos.' },
];

const Section3 = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % galleryImages.length);
    }, 10000); // Change image every 10 seconds

    return () => clearInterval(interval);
  }, []);

  const handleNewsItemClick = (index) => {
    setCurrentIndex(index);
  };

  const handleIndicatorClick = (index) => {
    setCurrentIndex(index);
  };

  const currentImage = galleryImages[currentIndex];

  return (
    <div className="section3" id="section3">
      <div className="section3-content-container">
        <div className="section3-left-content">
          <div className="section3-newsletter-title">
            <h1>News & Insights</h1>
          </div>
          <div className="section3-news-headlines">
            {newsHeadlines.map((item, index) => (
              <div
                className="section3-news-item"
                key={index}
                onClick={() => handleNewsItemClick(index)}
              >
                <img src={item.image} alt={item.title} className="section3-news-image" />
                <div className="section3-news-text">
                  <h3 className="section3-news-title">{item.title}</h3>
                  <p className="section3-news-subtitle">{item.subtitle}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="section3-gallery">
          <img src={currentImage.src} alt={`Gallery ${currentIndex + 1}`} className="section3-gallery-image" />
          <div className="section3-banner">
            <div className="section3-banner-content">
              <h2 className="section3-banner-title">{currentImage.title}</h2>
              <p className="section3-banner-body">{currentImage.body}</p>
            </div>
          </div>
          <div className="section3-progress-indicators">
            {galleryImages.map((_, index) => (
              <div
                key={index}
                className={`section3-indicator ${index === currentIndex ? 'active' : ''}`}
                onClick={() => handleIndicatorClick(index)}
              />
            ))}
          </div>
        </div>
      </div>

      {/* Horizontal line for Section 3 */}
      <img src="/line.png" alt="divider" className="section3-line" />
    </div>
  );
};

export default Section3;
