import React, { useState, useEffect } from 'react';
import SplashScreen from './SplashScreen';
import Section2 from './Section2';
import Section3 from './Section3';
import Gallery from './Gallery';
import Section4 from './Section4';
import Section5 from './Section5';
import Section6 from './Section6';
import ColorSchemesExample from './ColorSchemesExample';
import './Styles.css';
import { Link, Element } from 'react-scroll';
// Import your CSS for the website content

function App() {
  const [showSplash, setShowSplash] = useState(true);
  const [showContent, setShowContent] = useState(false); // Add state for showing content

  useEffect(() => {
    // Simulate loading time
    setTimeout(() => {
      setShowSplash(false); // Hide the splash screen after some time
      setShowContent(true); // Show the website content
    }, 3000); // Adjust the duration as needed
  }, []);

  return (
    <div className="App">
      {showSplash ? (
        <SplashScreen />
      ) : (
        <div className={`website-content ${showContent ? 'show-content' : ''}`}>
          <ColorSchemesExample />
          <Element name="Gallery">
          <Gallery />
    </Element>
        <Section2/>
        <Section3/>
       
       
        <Section6/>

         
          {/* Add the rest of your content here */}
        </div>
      )}
    </div>
  );
}

export default App;
